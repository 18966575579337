import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }   from '@angular/forms';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { UnitsComponent } from './units/units.component';
import { UnitComponent } from './unit/unit.component';
import { WordsComponent } from './words/words.component';
import { WordComponent } from './word/word.component';
import { SentescesComponent } from './sentesces/sentesces.component';
import { SentesceComponent } from './sentesce/sentesce.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { ConfigComponent } from './config/config.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BonusesComponent } from './bonuses/bonuses.component';
import { ExportDialogComponent } from './export-dialog/export-dialog/export-dialog.component';

@NgModule({
  declarations: [DashboardComponent, UnitsComponent, UnitComponent, WordsComponent, WordComponent, SentescesComponent, SentesceComponent, ImportDialogComponent, ConfigComponent, BonusesComponent, ExportDialogComponent],
  entryComponents: [ImportDialogComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule
  ]
})
export class DashboardModule { }
