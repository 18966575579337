import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitService } from 'src/app/service/unit.service';
import { ConfigService } from 'src/app/service/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'app-unit',
    templateUrl: './unit.component.html',
    styleUrls: ['./unit.component.scss'],
    providers: [UnitService]
})

export class UnitComponent extends BaseComponent implements OnInit {

    router: Router;
    
    unitReactiveForm: FormGroup;
    eikenLevels = []
    unitId: string;

    public mode = 'create';

    public title = "Add Unit"

    constructor(private unitService: UnitService, 
        private configService: ConfigService, 
        private ar: ActivatedRoute, 
        _router: Router, 
        private fb: FormBuilder, 
        private _snackBar: MatSnackBar, 
        public dialog: MatDialog) { 

        super(_snackBar, dialog)
            
        this.router = _router

        ar.params.subscribe(param => {
            if (param && param.id) {
                this.mode = 'edit';
                this.title = "Update Unit"
                this.loadUnitData(param.id);
            }
        });
    }

    ngOnInit() {
        this.initForm();

        this.loadConfig();
    }

    get f() { return this.unitReactiveForm.controls; }

    onSave() {
        if (this.unitReactiveForm.invalid) {
            return;
        }

        let unitData = {
            name_en: this.f.name_en.value,
            name_jp: this.f.name_jp.value,
            chapter: this.f.chapter.value,
            eiken_level: this.f.eiken_level.value,
            number: this.f.number.value,
            icons: JSON.stringify(this.f.icon.value ? [this.f.icon.value] : []),
            visibility: this.f.visibility.value
        }

        if (this.mode == 'create') {
            this.createUnit(unitData);
        } else {
            this.updateUnit(unitData);
        }
    }

    onDelete() {
        this.openDialog("Are you sure you want to delete this unit?", 
            "This action cannot be undone. The unit will be permanently deleted.",
            () => this.deleteUnit());
    }

    goBack() {
        this.router.navigate(['']);
    }

    private initForm() {
        this.unitReactiveForm = this.fb.group({
            name_en: ['', [
                Validators.required
            ]],
            name_jp: ['', [
                Validators.required
            ]],
            chapter: ['', []],
            eiken_level: ['', [
                Validators.required
            ]],
            number: ['', [
                Validators.required,
                Validators.pattern('[0-9]*')
            ]],
            icon: ['', []],
            visibility: [true, []]
        });
    }

    private loadUnitData(unitId) {
        this.unitId = unitId

        this.unitService.getUnit(unitId).subscribe( data => {
            if (data && data.data) {
                let unitData = data.data;

                if (unitData.icons) {
                    unitData.icon = unitData.icons[0];
                }

                this.unitReactiveForm.patchValue(unitData);
            }
        });
    }

    private loadConfig() {
        this.configService.getConfig().subscribe( data => {
            if (data && data.data && data.data.eiken_level) {
                this.eikenLevels = data.data.eiken_level.map(level => level.name);
            }
        } )
    }

    private createUnit(data) {
        this.unitService.createUnit(data).subscribe( data => {
            if (data && data.success) {
                this.showSnackBar('Unit successful created')
                this.goBack()
            }
        })
    }

    private updateUnit(data) {
        this.unitService.updateUnit(this.unitId, data).subscribe( data => {
            if (data && data.success) {
                this.showSnackBar('Unit successful updated')
                this.goBack()
            }
        })
    }

    private deleteUnit() {
        this.unitService.deleteUnit(this.unitId).subscribe( data => {
            if (data && data.success) {
                this.showSnackBar('Unit successful deleted')
                this.goBack()
            }
        })
    }
}
