import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.css']
})
export class RightsComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  signOut(): void {
    this.authService.SignOut();
  }

  redirectToDashboard(): void{
    this.router.navigate(['']);
  }

  redirectToGiveRights(): void{
    this.router.navigate(['/give-rights']);
  }
}
