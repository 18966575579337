import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
  selector: 'app-give-rights',
  templateUrl: './give-rights.component.html',
  styleUrls: ['./give-rights.component.scss']
})
export class GiveRightsComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  formEditor: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private _snackBar: MatSnackBar, 
    public dialog: MatDialog) { 
      super(_snackBar, dialog)
    }

  ngOnInit() {
    this.form = this.fb.group({
      adminEmail: ['', Validators.email],
    });
    this.formEditor = this.fb.group({
      editorEmail: ['', Validators.email],
    });
  }

  giveAdminRights() {
    const username = this.form.get('adminEmail').value;
    this.authService.GiveAdminRights(username).subscribe((res) =>
      this.showSnackBar(res.message)
    );
  }

  giveEditorRights() {
    const username = this.formEditor.get('editorEmail').value;
    this.authService.GiveEditorRights(username).subscribe((res) =>
      this.showSnackBar(res.message)
    );
  }
}
