import { Component, OnInit } from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-base',
    template: `NO UI TO BE FOUND HERE!`,
    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

    constructor(public snackBar: MatSnackBar, public dialog: MatDialog) { }

    ngOnInit() { }

    openDialog(title: string, message: string, next){
        const dialogRef = this.dialog.open(AlertComponent, { data: { title, message } });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result == true) {
                next();
            }
        })
    }

    showSnackBar(message) {
        this.snackBar.open(message, null, {
            duration: 2000
        });
    }
}
