import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { WordsService } from "src/app/service/words.service";
import { Router } from "@angular/router";
import { ImportDialogComponent } from "../import-dialog/import-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { UnitService } from "src/app/service/unit.service";
import { levels } from 'src/app/constants/const';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Word } from 'src/app/models/word';
import { ExportDialogComponent } from '../export-dialog/export-dialog/export-dialog.component';

@Component({
  selector: "app-words",
  templateUrl: "./words.component.html",
  styleUrls: ["./words.component.scss"],
  providers: [WordsService],
})

export class WordsComponent implements OnInit {
  router: Router;
  displayedColumns = [
    "id",
    "name_en",
    "name_jp",
    "word_jp_short",
    "word_jp_lib",
    "unit",
    "unit_name_jp",
    "number",
    "rank",
    "eiken_level",
    "CEFR_level",
    "word_type",
    "audio",
    "icons",
    "sentence",
  ];
  dataSource;
  fullDataSource;
  allUnits;
  allWords;
  currentLevel = '';
  currentUnit = '';
  units = [{name_en: ''}];
  words: Word[] = [];
  filteredWords: Observable<Word[]>;
  levels: string[] = levels;
  isLoading = true;
  wordControl = new FormControl();

  constructor(
    private wordsService: WordsService,
    private _router: Router,
    private unitsSevice: UnitService,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;

    this.router = _router;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.loadData();
    this.filteredWords = this.wordControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  addWord() {
    this.router.navigate(["words/create"]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      data: { dialogType: "words" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }

  onSelectLevel(level) {
    this.currentLevel = level.value;
    this.dataSource.data = this.fullDataSource.filter(
      (data) => data.eiken_level == this.currentLevel
    );
    this.units = this.allUnits.filter(
        (data) => data.eiken_level == this.currentLevel
    );
  }

  onSelectUnit(unit) {
    this.currentUnit = unit.value;
    this.dataSource.data = this.fullDataSource.filter(
        (data) => data.eiken_level == this.currentLevel && data.unit_name == this.currentUnit
    )
    this.words = this.allWords.filter(
        (data) => data.eiken_level == this.currentLevel && data.unit_name == this.currentUnit
    );
    this.saveWordsToDB();
  }

  onSelectWord(word) {
    this.dataSource.data = this.fullDataSource.filter(
        (data) => data.eiken_level == this.currentLevel &&
                data.unit_name == this.currentUnit &&
                data.name_en == word.value
    )
  }

  private loadData() {
    this.unitsSevice.getUnits().subscribe((data) => {
        if (data && data.data) {
            this.allUnits = data.data;
        }
    });
    this.wordsService.getAllWords().subscribe((data) => {
      this.isLoading = false;
      if (data && data.data) {
        this.allWords = data.data;
        this.words = this.allWords;
        this.saveWordsToDB();
        this.fullDataSource = this.viewData(data.data);
        this.dataSource.data = this.fullDataSource;
        this.dataSource.sort = this.sort;
      }
    });
  }

  private viewData(data) {
    return data.map((element) => {
      return {
        id: element.id,
        name_en: element.word_en,
        name_jp: element.word_jp,
        word_jp_short: element.word_jp_short,
        word_jp_lib: element.word_jp_lib,
        unit_name: element.unit_name,
        unit_name_jp: element.unit_name_jp,
        number: element.unit_order_number,
        rank: element.rank,
        eiken_level: element.eiken_level,
        CEFR_level: element.CEFR_level,
        word_type: element.word_type,
        audio: `${element.audio.length} files`,
        icons: `${element.icons.length} files`,
        sentence: element.sentences ? element.sentences.length > 0 : false,
      };
    });
  }

  private saveWordsToDB() {
    localStorage.setItem('words_key', JSON.stringify(this.words));
  }

  private _filter(value: string): Word[] {
    const filterValue = value.toLowerCase();

    return this.words.filter(option => option.word_en.toLowerCase().includes(filterValue));
  }

  openExportDailog() {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      data: {
        dialogType: "words",
        availableFields: [
          "id",
          "CEFR_level",
          "build_a_word",
          "eiken_level",
          "gradient",
          "icons",
          "speaking",
          "unit_id",
          "unit_name",
          "unit_name_jp",
          "unit_order_number",
          "voice",
          "word_en",
          "word_jp",
          "word_type",
          "word_jp_short",
          "word_jp_lib"
        ],
      },
    });
  }
}
