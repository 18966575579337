import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private apiClient: ApiService) { }

    getConfig() {
        return this.apiClient.get('config')
    }

    updateConfig(data) {
        return this.apiClient.put('config', data);
    }

    updateFont(font) {
        return this.apiClient.postFormData('config/font', [font]);
    }
}
