import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WordsService } from "src/app/service/words.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UnitService } from "src/app/service/unit.service";
import { ConfigService } from "src/app/service/config.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { BaseComponent } from "src/app/base/base.component";
import { MatSort } from "@angular/material/sort";
import { SentenceService } from "src/app/service/sentence.service";

@Component({
  selector: "app-word",
  templateUrl: "./word.component.html",
  styleUrls: ["./word.component.scss"],
  providers: [WordsService],
})
export class WordComponent extends BaseComponent implements OnInit {
  displayedColumns = ["file_name", "type", "action"];
  sentenceDisplayedColumns = [
    "id",
    "word_name",
    "sentence_en",
    "sentence_jp",
    "audio",
    "visibility",
    "action",
  ];

  audioDataSource = new MatTableDataSource([]);
  sentencesDataSource = new MatTableDataSource([]);

  public audio = [];
  isBackDisabled = false;
  isForwardDisabled = false;

  wordReactiveForm: FormGroup;

  router: Router;
  wordId;
  currentIndex: number;
  mode = "create";
  title = "Add Word";
  isLoading = true;
  isLoadingSentence = false;
  selectedUnit;

  units = [];
  words;
  word_type = [];
  eiken_level = [];
  sentences = [];

  baseWordData = {};
  public files = [];

  sentenceReactiveForm: FormGroup;

  sentenceId: string;
  validationErrors = [];

  constructor(
    private ar: ActivatedRoute,
    private wordsService: WordsService,
    private sentenceService: SentenceService,
    private unitService: UnitService,
    private configService: ConfigService,
    private fb: FormBuilder,
    private fbSentence: FormBuilder,
    _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    super(_snackBar, dialog);

    this.router = _router;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.initForm();
    this.initSentenceForm();

    this.loadData();

    this.ar.params.subscribe((param) => {
      if (param && param.id) {
        this.words = JSON.parse(localStorage.getItem("words_key"));
        // JSON.parse(this.ar.snapshot.queryParamMap.get("words"));
        this.mode = "edit";
        this.title = "Update Word";
        this.wordId = param.id;
        this.currentIndex = this.findWithAttr(this.words, "id", this.wordId);
        this.shouldDisableButtons(this.currentIndex);
      }
    });
  }

  private initForm() {
    this.wordReactiveForm = this.fb.group({
      word_en: ["", [Validators.required]],
      word_jp: ["", [Validators.required]],
      word_jp_short: ["", []],
      word_jp_lib: ["", []],
      unit: ["", [Validators.required]],
      unit_name_jp: ["", [Validators.required]],
      number: ["", [Validators.required, Validators.pattern("[0-9]*")]],
      rank: ["",  [Validators.required]],
      eiken_level: ["", [Validators.required]],
      CEFR_level: ["", []],
      word_type: ["", [Validators.required]],
      icon: ["", []],
      build_a_word_original: ["", []],
      gradient: ["", []],
      speaking: [true, []],
    });
  }

  private initSentenceForm() {
    this.sentenceReactiveForm = this.fbSentence.group({
      sentence_en: ["", [Validators.required]],
      sentence_jp: ["", []],
      sentence_word: ["", []],
      visibility: [true, []],
    });
  }

  get f() {
    return this.wordReactiveForm.controls;
  }

  get s() {
    return this.sentenceReactiveForm.controls;
  }

  get fileToUpload() {
    let audioToUpload = this.audio.filter((flie) => !flie.path);

    return audioToUpload;
  }

  private removeFileFromDataSource(file) {
    this.audio = this.audio.filter((element) => {
      return element != file;
    });
    this.audioDataSource.data = this.audio;
  }

  get wordData() {
    return {
      word_en: this.f.word_en.value,
      word_jp: this.f.word_jp.value,
      word_jp_short: this.f.word_jp_short.value ?? "",
      word_jp_lib: this.f.word_jp_lib.value ?? "",
      unit_name: this.f.unit.value.name_en,
      unit_name_jp: this.f.unit_name_jp.value,
      unit_id: this.f.unit.value.id,
      unit_order_number: this.f.number.value,
      rank: this.f.rank.value,
      eiken_level: this.f.eiken_level.value,
      CEFR_level: this.f.CEFR_level.value ?? "",
      word_type: this.f.word_type.value,
      build_a_word_original: this.f.build_a_word_original.value,
      speaking: this.f.speaking.value,
      gradient: this.f.gradient.value ? this.f.gradient.value : [],
      icons: JSON.stringify(this.f.icon.value ? [this.f.icon.value] : []),
      sentence: this.sentences.length > 0 ? true : false,
    };
  }
  // Actions

  onSave() {
    if (this.wordReactiveForm.invalid) {
      return;
    }

    this.mode == "create"
      ? this.createWord(this.wordData)
      : this.updateWord(this.wordId, this.wordData);
  }

  onSaveSentence() {
    if (this.sentenceReactiveForm.invalid) {
      return;
    }

    let sentenceData = {
      sentence_en: this.s.sentence_en.value,
      sentence_jp: this.s.sentence_jp.value ?? "",
      word_name: this.s.sentence_word.value,
      word_id: this.wordId,
      visibility: this.s.visibility.value,
    };

    this.validateSentence(sentenceData);
  }

  private createSentence(sentenceData) {
    this.sentenceService
      .createSentence(sentenceData, this.files)
      .subscribe((data) => {
        if (data && data.data) {
          this.changeSentenceStateIfNeeded();
          this.showSnackBar("Sentence successful created.");
          sentenceData["audio"] = [];
          sentenceData["id"] = data.data.id;
          this.sentences.push(sentenceData);
          this.sentencesDataSource.data = this.sentenceViewData(this.sentences);
        }
      });
  }

  private changeSentenceStateIfNeeded() {
    if (this.sentences.length <= 0) {
      this.updateWord(this.wordId, this.wordData);
    }
  }

  private validateSentence(sentenceData) {
    this.sentenceService.validateSentence(sentenceData).subscribe((data) => {
      this.isLoadingSentence = false;
      if (!data && !data.data) {
        return;
      }
      if (data.data.validation == true) {
        this.createSentence(sentenceData);
      } else {
        this.validationErrors = data.data.errors;
        this.s.sentence_en.setErrors({ invalid: true });
        this.s.sentence_en.markAsTouched();
        this.openDialog(
          "You have a validation error.",
          "Do you want to continue?",
          () => this.createSentence(sentenceData)
        );
      }
    });
  }

  onDelete() {
    this.openDialog(
      "Are you sure you want to delete this word?",
      "This action cannot be undone. The word will be permanently deleted.",
      () => this.deleteWord(this.wordId)
    );
  }

  goBack() {
    this.router.navigate(["words"]);
  }

  uploadAudio($event) {
    let selectedFiles = Object.values($event.target.files);

    selectedFiles.forEach((file) => {
      this.audio.push(file);
    });

    this.audioDataSource.data = this.audio;
  }

  deleteFile(file) {
    if (file.path) {
      this.openDialog(
        "Are you sure you want to delete this file from the word?",
        "This action cannot be undone. The file will be permanently deleted.",
        () => this.removeFile(file)
      );
    } else {
      this.removeFileFromDataSource(file);
    }
  }

  deleteSentence(sentence) {
    let id = sentence.id;
    this.sentences = this.sentences.filter((item) => item.id !== id);
    this.sentencesDataSource.data = this.sentenceViewData(this.sentences);
    this.sentenceService.deleteSentence(id).subscribe((data) => {
      if (data && data.success) {
        this.showSnackBar("Sentence successful deleted");
      }
    });
  }

  selectRow(row) {
    this.router.navigate([`/sentences/${row.id}`]);
  }

  // API

  private loadData() {
    forkJoin([
      this.configService.getConfig(),
      this.unitService.getUnits(),
    ]).subscribe((results) => {
      let configResult = results[0];
      let unitsResult = results[1];

      if (
        configResult &&
        unitsResult &&
        configResult.data &&
        unitsResult.data
      ) {
        this.eiken_level = configResult.data.eiken_level.map(
          (level) => level.name
        );
        this.word_type = configResult.data.word_type;
        this.units = unitsResult.data;
        this.loadWordDataIfNeeded();
      } else {
        this.isLoading = false;
        if (configResult.message) {
          this.showSnackBar(configResult.message);
        } else if (unitsResult.message) {
          this.showSnackBar(unitsResult.message);
        } else {
          this.showSnackBar("Server Error");
        }
      }
    });
  }

  private loadWordDataIfNeeded() {
    if (this.wordId) {
      this.loadWordData(this.wordId);
    } else {
      this.isLoading = false;
    }
  }

  private loadWordData(wordId) {
    this.loadWord(this.currentIndex);
    this.loadSentences();
  }

  private loadSentences() {
    this.wordsService.getWordSentences(this.wordId).subscribe((data) => {
      this.isLoading = false;
      if (data && data.data) {
        this.sentences = data.data;
        this.sentencesDataSource.data = this.sentenceViewData(this.sentences);
        this.sentencesDataSource.sort = this.sort;
      } else {
        this.showSnackBar(data.message ? data.message : "Server Error");
      }
    });
  }

  private createWord(data) {
    this.wordsService.createWord(data, this.fileToUpload).subscribe((data) => {
      if (data && data.data) {
        this.showSnackBar("Word successful created.");
        this.goBack();
      } else {
        this.showSnackBar(data.message ? data.message : "Server Error");
      }
    });
  }

  private updateWord(wordId, data) {
    let wordData = {...data};
    wordData['audio'] = this.fileToUpload;
    wordData['id'] = wordId;
    let unit = this.units.find(
        (item) => String(item.id) === String(wordData.unit_id)
    );
    wordData['unit'] = unit;
    this.words[this.currentIndex] = wordData;
    this.wordsService
      .updateWord(wordId, data, this.fileToUpload)
      .subscribe((data) => {
        if (data && data.success) {
          this.showSnackBar("Word successful updated.");
        } else {
          this.showSnackBar(data.message ? data.message : "Server Error");
        }
      });
  }

  private deleteWord(wordId) {
    this.wordsService.deleteWord(wordId).subscribe((data) => {
      if (data && data.success) {
        this.showSnackBar("Word successful deleted.");
        this.goBack();
      } else {
        this.showSnackBar(data.message ? data.message : "Server Error");
      }
    });
  }

  private removeFile(file) {
    this.wordsService.deleteFile(this.wordId, file.path).subscribe((data) => {
      if (data && data.success) {
        this.showSnackBar(`File: ${file.name} successful deleted`);
        this.removeFileFromDataSource(file);
      } else {
        this.showSnackBar(data.message ? data.message : "Server Error");
      }
    });
  }

  private sentenceViewData(data) {
    return data.map((element) => {
      return {
        id: element.id,
        word_name: element.word_name,
        sentence_en: element.sentence_en,
        sentence_jp: element.sentence_jp,
        audio: `${element.audio.length} files`,
        visibility: element.visibility,
      };
    });
  }

  //Fast navigation

  forward() {
    this.currentIndex = this.findWithAttr(this.words, "id", this.wordId) + 1;
    this.loadWord(this.currentIndex);
  }

  back() {
    this.currentIndex = this.findWithAttr(this.words, "id", this.wordId) - 1;
    this.loadWord(this.currentIndex);
  }

  private shouldDisableForwardButton(index) {
    if (this.words.length - 1 <= index) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }

  private shouldDisableBackButton(index) {
    if (index <= 0) {
      this.isBackDisabled = true;
    } else {
      this.isBackDisabled = false;
    }
  }

  private shouldDisableButtons(index) {
    this.shouldDisableForwardButton(index);
    this.shouldDisableBackButton(index);
  }

  private loadWord(index) {
    this.shouldDisableButtons(index);

    let wordData = this.words[index];
    this.wordId = wordData.id;

    let unit = this.units.find(
      (item) => String(item.id) === String(wordData.unit_id)
    );

    this.audio = wordData.audio;

    this.audioDataSource.data = this.audio;

    wordData.unit = unit;
    wordData.number = wordData.unit_order_number;

    if (wordData.icons) {
      wordData.icon = wordData.icons[0];
    }

    this.wordReactiveForm.patchValue(wordData);
    this.baseWordData = wordData;
    this.sentenceReactiveForm.patchValue({
      sentence_word: wordData?.word_en,
    });
    this.loadSentences();
  }

  private findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
}
