import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { UnitsComponent } from './units/units.component';
import { UnitComponent } from './unit/unit.component';
import { WordsComponent } from './words/words.component';
import { WordComponent } from './word/word.component';
import { SentescesComponent } from './sentesces/sentesces.component';
import { SentesceComponent } from './sentesce/sentesce.component';
import { ConfigComponent } from './config/config.component';
import { AuthGuard } from 'src/app/core/auth.guard';
import { BonusesComponent } from './bonuses/bonuses.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard], children: [
    { path: '', component: UnitsComponent, canActivate: [AuthGuard], },
    { path: 'units', component: UnitsComponent, canActivate: [AuthGuard], },
    { path: 'units/create', component: UnitComponent, canActivate: [AuthGuard], },
    { path: 'units/:id', component: UnitComponent, canActivate: [AuthGuard], },
    { path: 'words', component: WordsComponent, canActivate: [AuthGuard], },
    { path: 'words/create', component: WordComponent, canActivate: [AuthGuard], },
    { path: 'words/:id', component: WordComponent, canActivate: [AuthGuard], },
    { path: 'sentences', component: SentescesComponent, canActivate: [AuthGuard], },
    { path: 'sentences/create', component: SentesceComponent, canActivate: [AuthGuard], },
    { path: 'sentences/:id', component: SentesceComponent, canActivate: [AuthGuard], },
    { path: 'bonuses', component: BonusesComponent, canActivate: [AuthGuard], },
    { path: 'config', component: ConfigComponent, canActivate: [AuthGuard], }
  ] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
