import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "src/app/service/api.service";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  ValidatorFn,
} from "@angular/forms";

@Component({
  selector: "app-export-dialog",
  templateUrl: "./export-dialog.component.html",
  styleUrls: ["./export-dialog.component.scss"],
})
export class ExportDialogComponent implements OnInit {
  form: FormGroup;

  title;
  dialogType = "units";
  availableFields = [];
  errorMessage = "";

  spreadsheetIdFormControl = new FormControl("", [Validators.required]);
  titleFormControl = new FormControl("", []);

  get ordersFormArray() {
    return this.form.controls.orders as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder
  ) {
    this.dialogType = data.dialogType;
    this.availableFields = data.availableFields.map((field) => {
      return { id: data.availableFields.indexOf(field), name: field };
    });

    this.form = this.formBuilder.group({
      orders: new FormArray([], this.minSelectedCheckboxes(1)),
    });

    this.addCheckboxes();
  }

  private addCheckboxes() {
    this.availableFields.forEach(() =>
      this.ordersFormArray.push(new FormControl(false))
    );
  }

  ngOnInit() {
    this.setupDialog();
  }

  private setupDialog() {
    this.title = `Export ${this.dialogType} from Google Sheet`;
  }

  private get selectedCheckbox() {
    return this.form.value.orders
      .map((checked, i) => (checked ? this.availableFields[i].name : null))
      .filter((v) => v !== null);
  }

  private minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
  
      return totalSelected >= min ? null : { required: true };
    };
  
    return validator;
  }

  onExport() {
    this.apiService
      .post(`${this.dialogType}/export`, {
        spreadsheetId: this.spreadsheetIdFormControl.value,
        headerValues: this.selectedCheckbox,
        title: this.titleFormControl.value,
      })
      .subscribe((data) => {
        if (data && data.data) {
          this.dialogRef.close();
        }
      });
  }
}
