import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class WordsService {

    constructor(private apiClient: ApiService) { }

    getAllWords() {
        return this.apiClient.get('words');
    }

    getWord(wordId) {
        return this.apiClient.get(`words/${wordId}`);
    }

    getWordSentences(wordId) {
        return this.apiClient.get(`words/${wordId}/sentences`)
    }

    createWord(data, files = []) {
        return this.apiClient.postFormData(`words`, files, data);
    }

    deleteWord(wordId) {
        return this.apiClient.delete(`words/${wordId}`)
    }

    deleteFile(wordId, filePath) {
        let options = { body: { path: filePath } }
        return this.apiClient.delete(`words/${wordId}/file`, options)
    }
    
    updateWord(wordId, data, files = []) {
        return this.apiClient.putFromData(`words/${wordId}`, files, data)
    }
}
