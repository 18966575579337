import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BonusesService {

  constructor(private apiClient: ApiService) { }

  giveFreeEiken(data) {
    return this.apiClient.post('bonuses/free-eiken', data);
  }
}
