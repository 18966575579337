import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    title: string;
    message: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef:MatDialogRef<AlertComponent>) {
        this.title = data.title
        this.message = data.message
    }

    ngOnInit() {}

}
