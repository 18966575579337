import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(private apiClient: ApiService) { }

  getUnits() {
    return this.apiClient.get('units')
  }

  getUnit(unitId) {
    return this.apiClient.get(`units/${unitId}`)
  }

  createUnit(data, files = []) {
    return this.apiClient.postFormData(`units`, files, data);
  }

  deleteUnit(unitId) {
    return this.apiClient.delete(`units/${unitId}`)
  }

  deleteFile(unitId, filePath) {
    let options = { body: { path: filePath } }
    return this.apiClient.delete(`units/${unitId}/file`, options)
  }

  updateUnit(unitId, data, files = []) {
    return this.apiClient.putFromData(`units/${unitId}`, files, data)
  }
}
