import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { WordsService } from "src/app/service/words.service";
import { SentenceService } from "src/app/service/sentence.service";
import { MatDialog } from "@angular/material/dialog";
import { BaseComponent } from "src/app/base/base.component";
import { UnitService } from "src/app/service/unit.service";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { Word } from "src/app/models/word";

interface WordGroup {
  name: string;
  word: Word[];
}

export const _filter = (opt: Word[], value: string): Word[] => {
  const filterValue = value.toString().toLowerCase();

  return opt.filter(
    (item) => item.word_en.toLowerCase().indexOf(filterValue) === 0
  );
};

@Component({
  selector: "app-sentesce",
  templateUrl: "./sentesce.component.html",
  styleUrls: ["./sentesce.component.scss"],
})
export class SentesceComponent extends BaseComponent implements OnInit {
  displayedColumns = ["file_name", "type", "action"];
  dataSource = new MatTableDataSource([]);
  public files = [];

  router: Router;

  sentenceReactiveForm: FormGroup;

  isLoading = true;
  isBackDisabled = false;
  isForwardDisabled = false;
  wordGroup: WordGroup[];
  words = [];
  wordForm: FormGroup = this._fb.group({
    word: "",
  });
  sentenceId: string;
  currentIndex: number;
  mode = "create";
  title = "Add Sentence";
  validationErrors = [];
  wordGroupOptions: Observable<WordGroup[]>;
  sentences = [];

  constructor(
    private sentenceServicve: SentenceService,
    private wordsService: WordsService,
    private unitsSevice: UnitService,
    private ar: ActivatedRoute,
    _router: Router,
    private fb: FormBuilder,
    private _fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    super(_snackBar, dialog);

    this.router = _router;

    this.createSentence = this.createSentence.bind(this);
    this.updateSentence = this.updateSentence.bind(this);
  }

  ngOnInit() {
    this.initForm();
    this.loadWords();

    this.ar.params.subscribe((param) => {
      if (param && param.id) {
        // this.sentences = JSON.parse(
        //   this.ar.snapshot.queryParamMap.get("sentences")
        // );
        this.sentences = JSON.parse(localStorage.getItem("sentences_key"));
        this.mode = "edit";
        this.title = "Update Sentence";
        this.sentenceId = param.id;
        this.currentIndex = this.findWithAttr(
          this.sentences,
          "id",
          this.sentenceId
        );
        this.shouldDisableButtons(this.currentIndex);
      }
    });

    this.wordGroupOptions = this.wordForm.get("word")!.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterGroup(value))
    );
  }

  private _filterGroup(value: string): WordGroup[] {
    if (value) {
      return this.wordGroup
        .map((group) => ({
          name: group.name,
          word: _filter(group.word, value),
        }))
        .filter((group) => group.word.length > 0);
    }

    return this.wordGroup;
  }

  private initForm() {
    this.sentenceReactiveForm = this.fb.group({
      sentence_en: ["", [Validators.required]],
      sentence_jp: ["", []],
      wordForm: ["", []],
      principle: ["", []],
      visibility: [true, []],
      choice_one: ["", []],
      choice_two: ["", []],
      choice_three: ["", []],
    });
  }

  get f() {
    return this.sentenceReactiveForm.controls;
  }

  displayFn(word: Word): string {
    return word?.word_en;
  }

  onSave() {
    if (this.sentenceReactiveForm.invalid) {
      return;
    }

    let sentenceData = {
      sentence_en: this.f.sentence_en.value,
      sentence_jp: this.f.sentence_jp.value ?? "",
      word_name: this.wordForm.value.word.word_en,
      word_id: this.wordForm.value.word.id,
      principle: this.f.principle.value,
      visibility: this.f.visibility.value,
      choices: undefined,
    };
    if (!sentenceData.principle) {
      delete sentenceData.principle;
    }

    const choicesList = [];
    if (this.f.choice_one.value) {
      choicesList.push(this.f.choice_one.value);
    }
    if (this.f.choice_two.value) {
      choicesList.push(this.f.choice_two.value);
    }
    if (this.f.choice_three.value) {
      choicesList.push(this.f.choice_three.value);
    }
    if (choicesList.length > 0) {
      sentenceData.choices = choicesList;
    } else {
      delete sentenceData.choices;
    }

    let next =
      this.mode == "create" ? this.createSentence : this.updateSentence;

    this.validateSentence(sentenceData, next);
  }

  onDelete() {
    this.openDialog(
      "Are you sure you want to delete this sentence?",
      "This action cannot be undone. The sentence will be permanently deleted.",
      () => this.deleteSentence(this.sentenceId)
    );
  }

  goBack() {
    this.router.navigate(["sentences"]);
  }

  goToWords() {
    this.router.navigate(["words"]);
  }

  uploadFile($event) {
    let selectedFiles = Object.values($event.target.files);

    selectedFiles.forEach((file) => {
      this.files.push(file);
    });

    this.dataSource.data = this.files;
  }

  deleteFile(file) {
    if (file.path) {
      this.openDialog(
        "Are you sure you want to delete this file from the sentence?",
        "This action cannot be undone. The file will be permanently deleted.",
        () => this.removeFile(file)
      );
    } else {
      this.files = this.files.filter((element) => {
        return element != file;
      });
      this.dataSource.data = this.files;
    }
  }

  private loadSentenceIfNeeded(): void {
    if (!this.sentenceId) {
      this.isLoading = false;
      return;
    }

    this.loadSentence(this.currentIndex);
    this.isLoading = false;
  }

  private loadWords() {
    this.unitsSevice.getUnits().subscribe((unitsData) => {
      if (unitsData && unitsData.data) {
        this.wordsService.getAllWords().subscribe((wordsData) => {
          if (wordsData && wordsData.data) {
            this.words = wordsData.data;
            this.wordGroup = this.prepareWords(unitsData.data, wordsData.data);
            this.loadSentenceIfNeeded();
          } else {
            this.isLoading = false;
          }
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  private prepareWords(units, words): [] {
    let sortedUnits = units.sort((a, b) =>
      a.eiken_level < b.eiken_level ? 1 : a.eiken_level > b.eiken_level ? -1 : 0
    );
    return sortedUnits.map((unit) => {
      let wordsFiltered = words.filter(
        (word) =>
          word.unit_name === unit.name_en &&
          word.eiken_level === unit.eiken_level
      );
      return {
        name: unit.name_en + " " + unit.eiken_level,
        word: wordsFiltered,
      };
    });
  }

  private createSentence(data) {
    this.sentenceServicve.createSentence(data, this.files).subscribe((data) => {
      if (data && data.data) {
        this.showSnackBar("Sentence successful created.");
        this.goToWords();
      }
    });
  }

  private updateSentence(data) {
    let filesToUpload = this.files.filter((flie) => !flie.path);
    let sentenceData = {...data};
    sentenceData['id'] = this.sentenceId;
    sentenceData['audio'] = filesToUpload;
    this.sentences[this.currentIndex] = sentenceData;

    this.sentenceServicve
      .updateSentence(this.sentenceId, data, filesToUpload)
      .subscribe((data) => {
        if (data && data.data) {
          this.showSnackBar("Sentence successful updated.");
        }
      });
  }

  private deleteSentence(sentenceId) {
    this.sentenceServicve.deleteSentence(sentenceId).subscribe((data) => {
      if (data && data.success) {
        this.showSnackBar("Sentence successful deleted");
        this.goToWords();
      }
    });
  }

  private validateSentence(sentenceData, next) {
    this.isLoading = true;
    this.sentenceServicve.validateSentence(sentenceData).subscribe((data) => {
      this.isLoading = false;
      if (!data && !data.data) {
        return;
      }
      if (data.data.validation == true) {
        next(sentenceData);
      } else {
        this.validationErrors = data.data.errors;
        this.f.sentence_en.setErrors({ invalid: true });
        this.f.sentence_en.markAsTouched();
        this.openDialog(
          "You have a validation error.",
          "Do you want to continue?",
          () => next(sentenceData)
        );
      }
    });
  }

  private removeFile(file) {
    this.sentenceServicve
      .deleteFile(this.sentenceId, file.path)
      .subscribe((data) => {
        if (data && data.success) {
          this.showSnackBar(`File: ${file.name} successful deleted`);
          this.files = this.files.filter((element) => {
            return element != file;
          });
          this.dataSource.data = this.files;
        }
      });
  }

  forward() {
    this.currentIndex =
      this.findWithAttr(this.sentences, "id", this.sentenceId) + 1;
    this.loadSentence(this.currentIndex);
  }

  back() {
    this.currentIndex =
      this.findWithAttr(this.sentences, "id", this.sentenceId) - 1;
    this.loadSentence(this.currentIndex);
  }

  private shouldDisableForwardButton(index) {
    if (this.sentences.length - 1 <= index) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }

  private shouldDisableBackButton(index) {
    if (index <= 0) {
      this.isBackDisabled = true;
    } else {
      this.isBackDisabled = false;
    }
  }

  private shouldDisableButtons(index) {
    this.shouldDisableForwardButton(index);
    this.shouldDisableBackButton(index);
  }

  private loadSentence(index) {
    this.shouldDisableButtons(index);

    let nextSentenceData = this.sentences[index];
    this.sentenceId = nextSentenceData.id;

    let word = this.words.filter(
      (word) => (word.id === nextSentenceData.word_id)
    )[0];

    let choicesList = nextSentenceData.choices;

    let choiceOne;
    let choiceTwo;
    let choiceThree;
    if (choicesList) {
      if (choicesList.length > 0) {
        choiceOne = choicesList[0];
      }
      if (choicesList.length > 1) {
        choiceTwo = choicesList[1];
      }
      if (choicesList.length > 2) {
        choiceThree = choicesList[2];
      }
    }

    this.files = nextSentenceData.audio;
    this.dataSource.data = this.files;

    this.sentenceReactiveForm.patchValue({
      sentence_en: nextSentenceData.sentence_en,
      wordForm: this._fb.group({
        word: word?.word_en,
      }),
      sentence_jp: nextSentenceData.sentence_jp,
      principle: nextSentenceData.principle,
      visibility: nextSentenceData.visibility,
      choice_one: choiceOne,
      choice_two: choiceTwo,
      choice_three: choiceThree,
    });
    this.wordForm.patchValue({
      word: word,
    });
    this.wordForm.setValue({
      word: word,
    });
  }

  private findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
}
