import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ConfigService} from 'src/app/service/config.service';
import {BaseComponent} from 'src/app/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent extends BaseComponent implements OnInit {

  configReactiveForm: FormGroup;
  eikenLevelReactiveForm: FormGroup;
  wordTypeReactiveForm: FormGroup;
  isLoading = true;

  // Font
  fontDisplayedColumns = ['glyph_name', 'unicode'];
  fontDataSource = new MatTableDataSource([]);
  // Eiken_level
  eikenLevelDisplayedColumns = ['name', 'key', 'complexity', 'action'];
  eikenLevelDataSource = new MatTableDataSource([]);
  // Word_type
  wordTypeDisplayedColumns = ['name', 'action'];
  wordTypeDataSource = new MatTableDataSource([]);

  constructor(private configService: ConfigService, private fb: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    super(_snackBar, dialog);
  }

  ngOnInit() {
    this.initForms();

    this.loadData();
  }

  private initForms() {
    this.configReactiveForm = this.fb.group({
      yes_no_probability: ['', [
        Validators.required,
        Validators.pattern('[0-9]*')
      ]],
      app_time: ['', [
        Validators.required,
        Validators.pattern('[0-9]*')
      ]],
      words_per_session: ['', [
        Validators.required,
        Validators.pattern('[0-9]*')
      ]],
    });

    this.eikenLevelReactiveForm = this.fb.group({
      name: ['', [
        Validators.required
      ]],
      key: ['', [
        Validators.required
      ]],
      complexity: ['', [
        Validators.required,
        Validators.pattern('[0-9]*')
      ]]
    });

    this.wordTypeReactiveForm = this.fb.group({
      name: ['', [
        Validators.required
      ]]
    });
  }

  get f() {
    return this.configReactiveForm.controls;
  }

  addEikenLevel() {
    if (this.eikenLevelReactiveForm.invalid) {
      return;
    }

    const f = this.eikenLevelReactiveForm.controls;

    const level = {
      name: f.name.value,
      key: f.key.value,
      complexity: f.complexity.value
    };

    this.eikenLevelReactiveForm.reset();

    this.eikenLevelDataSource.data.push(level);
    this.eikenLevelDataSource.data = this.eikenLevelDataSource.data;
  }

  addWordType() {
    if (this.wordTypeReactiveForm.invalid) {
      return;
    }

    const f = this.wordTypeReactiveForm.controls;

    this.wordTypeDataSource.data.push(f.name.value);
    this.wordTypeDataSource.data = this.wordTypeDataSource.data;
    this.wordTypeReactiveForm.reset();
  }

  removeEikenLevel(element) {
    this.eikenLevelDataSource.data = this.eikenLevelDataSource.data.filter(level => level != element);
  }

  removeWordType(element) {
    this.wordTypeDataSource.data = this.wordTypeDataSource.data.filter(type => type != element);
  }

  uploadFont($event) {
    const selectedFiles = Object.values($event.target.files);

    this.updateFont(selectedFiles.pop());
  }

  onSave() {
    if (this.configReactiveForm.invalid) {
      return;
    }

    const configData = {
      yes_no_probability: this.f.yes_no_probability.value,
      app_time: this.f.app_time.value,
      words_per_session: this.f.words_per_session.value,
      eiken_level: this.eikenLevelDataSource.data,
      word_type: this.wordTypeDataSource.data
    };

    this.updateConfig(configData);
  }

  private loadData() {
    this.configService.getConfig().subscribe(data => {
      this.isLoading = false;
      if (data && data.data) {
        const configData = data.data;

        this.configReactiveForm.patchValue({
          yes_no_probability: configData.yes_no_probability,
          app_time: configData.app_time,
          words_per_session: configData.words_per_session,
        });

        this.fontDataSource.data = data.data.icons;
        this.eikenLevelDataSource.data = data.data.eiken_level;
        this.wordTypeDataSource.data = data.data.word_type;
      } else {
        this.showSnackBar(data.message ? data.message : 'Server Error');
      }
    });
  }

  private updateConfig(data) {
    this.configService.updateConfig(data).subscribe(data => {
      if (data && data.data) {
        this.showSnackBar('Config successful updated');
      } else {
        this.showSnackBar(data.message ? data.message : 'Server Error');
      }
    });
  }

  private updateFont(font) {
    this.configService.updateFont(font).subscribe(data => {
      if (data && data.data) {
        this.showSnackBar('Font successful updated');
        this.loadData();
      } else {
        this.showSnackBar(data.message ? data.message : 'Server Error');
      }
    });
  }
}
