import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: firebase.User; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    private apiClient: ApiService
  ) {
    
    this.afAuth.authState.subscribe(async user => {
      if (user) {
        this.userData = user;
        let token = await user.getIdTokenResult();
        localStorage.setItem('admin', JSON.stringify(token.claims.admin ? true : false));
        localStorage.setItem('editor', JSON.stringify(token.claims.editor ? true : false));
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('admin', null);
        localStorage.setItem('editor', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
            }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
    .then((user) => {
      user.sendEmailVerification();
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
          }).catch((error) => {
      window.alert(error)
    })
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('admin');
      localStorage.removeItem('editor');
      this.router.navigate(['sign-in']);
    })
  }

  get isAdminOrEditor(): boolean {
    return this.isAdmin || this.isEditor;
  }

  get isAdmin(): boolean {
    return JSON.parse(localStorage.getItem('admin'));
  }

  get isEditor(): boolean {
    return JSON.parse(localStorage.getItem('editor'));
  }

  GiveAdminRights(email: String) {
    return this.apiClient.get(`/rights/admin/${email}`);
  }

  GiveEditorRights(email: String) {
    return this.apiClient.get(`/rights/editor/${email}`);
  }
}
