import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {

    displayedColumns = ['id', 'name'];

    title;
    dialogType = "units";
    errorMessage = "";
    spreadsheetId: string;

    greeds = [];
    greedsDataSource = new MatTableDataSource([]);

    spreadsheetIdFormControl = new FormControl('', [
        Validators.required
    ])

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef:MatDialogRef<ImportDialogComponent>, private apiService: ApiService) {
        this.dialogType = data.dialogType;
    }

    ngOnInit() {
        this.setupDialog();
    }

    onImport() {
        if (this.spreadsheetIdFormControl.valid) {
            this.getGreeds();
        }
    }
    selectRow(row) {
        this.importData(row.index);
    }

    private setupDialog() {
        this.title = `Import ${this.dialogType} from Google Sheet`
    }

    private getGreeds() {
        this.apiService.get(`${this.dialogType}/greeds?spreadsheetId=${this.spreadsheetIdFormControl.value}`)
        .subscribe(data => {
            if (data && data.data) {
                this.greeds = data.data;
                this.greedsDataSource.data = this.greeds;
            }
        })
    }

    private importData(greedIndex) {
        this.apiService.post(`${this.dialogType}/import`, { spreadsheetId: this.spreadsheetIdFormControl.value, greedIndex: greedIndex })
            .subscribe(data => {
                if (data && data.data) {
                    this.dialogRef.close();
                }
            })
    }
 
}
