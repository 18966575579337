import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public isMobile = false
    public isOpen = false

    constructor() { }

    ngOnInit() { 
        this.onResize();
    }

    onResize() {
        this.isMobile = window.innerWidth < 600;
        this.isOpen = !this.isMobile
    }
}
