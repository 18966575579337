import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, filter, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface IApiResponse {
	success: boolean;
	data?: any;
	message?: string;
}

@Injectable()
export class ApiService {

	constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

	public get(route: string, options: any = {}): Observable<IApiResponse> {
		return this.http.get<IApiResponse>(environment.host+route, Object.assign(options, {observe: 'response'}))
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}

	public post(route: string, data: any = {}): Observable<IApiResponse> {
		return this.http.post(environment.host+route, data, {observe: 'response'})
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}

	public postFormData(route: string, files: Array<any> = [], data: any = {}): Observable<IApiResponse> {
		let formData = new FormData();
		let counter = 0;

		files.forEach( file => {
			formData.append(`file${counter}`, file)
			counter++;
		});

		Object.keys(data).forEach(key => {
			formData.append(key, data[key]);
		});

		return this.http.post(environment.host+route, formData, {observe: 'response'})
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}

	public delete(route: string, options: any = {}): Observable<IApiResponse> {
		return this.http.delete(environment.host+route, Object.assign(options, {observe: 'response'}))
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}

	private catchError(response: HttpErrorResponse) {
        console.log('ApiService.catchError', response);
		
		this.checkResponse(response.error, response.url);

        return new Observable<any>();
	}
	
	public put(route: string, body: any | null, options: any = {}): Observable<IApiResponse> {
		return this.http.put(environment.host+route, body, Object.assign(options, {observe: 'response'}))
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}	

	public putFromData(route: string, files: Array<any> = [], data: any = {}): Observable<IApiResponse> {
		let formData = new FormData();
		let counter = 0;

		files.forEach( file => {
			formData.append(`file${counter}`, file)
			counter++;
		});

		Object.keys(data).forEach(key => {
			formData.append(key, data[key]);
		});

		return this.http.put(environment.host+route, formData, {observe: 'response'})
			.pipe(
				catchError((responce) => this.catchError(responce)),
				filter((response: HttpResponse<IApiResponse>) => this.checkResponse(response.body, response.url)),
				map((response: HttpResponse<IApiResponse>) => response.body)
			)
	}

	private checkResponse(apiResponse: IApiResponse, url: string): boolean {
        if (apiResponse.success) {
            if (apiResponse.message) {
				this.showSnackBar(apiResponse.message);
            }
            return true;
		}
		
        console.error(url);
		console.error(apiResponse);
		this.showSnackBar(apiResponse.message ? apiResponse.message : "Server error");
		
		return false;
	}

	private showSnackBar(message) {
        this._snackBar.open(message, null, {
            duration: 2000
        });
    }

} 
