import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UnitService } from 'src/app/service/unit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImportDialogComponent } from '../import-dialog/import-dialog.component';
import { levels } from 'src/app/constants/const';
import { Unit } from 'src/app/models/unit';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { ExportDialogComponent } from '../export-dialog/export-dialog/export-dialog.component';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
  providers: [UnitService]
})

export class UnitsComponent implements OnInit {

  router: Router;
  displayedColumns = ['id', 'name_en', 'name_jp', 'chapter', 'eiken_level', 'number', 'icons', 'visibility'];
  dataSource;
  fullDataSource;
  allUnits;
  currentLevel = '';
  units: Unit[] = [];
  filteredUnits: Observable<Unit[]>;
  levels: string[] = levels;
  isLoading = true;
  unitControl = new FormControl();

  constructor(private unitService: UnitService, private route: ActivatedRoute, _router: Router, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;

    this.router = _router
   }

   openDialog() {
    const dialogRef = this.dialog.open(ImportDialogComponent, { data: { dialogType: "units" } });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

   @ViewChild(MatSort, {static: true}) sort: MatSort;

   ngOnInit() {
     this.loadData();
     this.filteredUnits = this.unitControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
   }

   selectRow(row) {
    this.router.navigate([ `/units/${row.id}` ]);
  }

   viewData(data) {
     return data.map(element => {
       return {
        id: element.id,
        name_en: element.name_en,
        name_jp: element.name_jp,
        chapter: element.chapter,
        eiken_level: element.eiken_level,
        number: element.number,
        icons: `${element.icons.length} files`,
        visibility: element.visibility
       }
     })
   }

   addUnit() {
     this.router.navigate(['/units/create']);
   }

   private loadData() {
    this.unitService.getUnits().subscribe(data => {
      this.isLoading = false;
      if (data && data.data) {
        this.allUnits = data.data;
        this.fullDataSource = this.viewData(data.data);
        this.dataSource.data = this.fullDataSource;
        this.dataSource.sort = this.sort;
      }
    })
   }

   onSelectLevel(level) {
    this.currentLevel = level.value;
    this.dataSource.data = this.fullDataSource.filter(
      (data) => data.eiken_level == this.currentLevel
    );
    this.units = this.allUnits.filter(
        (data) => data.eiken_level == this.currentLevel
    );
  }

  onSelectUnit(unit) {
    this.dataSource.data = this.fullDataSource.filter(
        (data) => data.eiken_level == this.currentLevel && data.name_en == unit.value
    )
  }

  private _filter(value: string): Unit[] {
    const filterValue = value.toLowerCase();

    return this.units.filter(option => option.name_en.toLowerCase().includes(filterValue));
  }

  openExportDailog() {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      data: {
        dialogType: "units",
        availableFields: [
          "id",
          "chapter",
          "eiken_level",
          "icons",
          "name_en",
          "name_jp",
          "number",
          "visibility"
        ],
      },
    });
  }
}