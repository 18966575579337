import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SentenceService {

    constructor(private apiClient: ApiService) { }

    getSentences() {
        return this.apiClient.get('sentences');
    }
    
    getSentence(sentenceId) {
        return this.apiClient.get(`sentences/${sentenceId}`);
    }
    
    createSentence(data, files = []) {
        return this.apiClient.postFormData(`sentences`, files, data);
    }
    
    deleteSentence(sentenceId) {
        return this.apiClient.delete(`sentences/${sentenceId}`);
    }
    
    deleteFile(sentenceId, filePath) {
        let options = { body: { path: filePath } }
        return this.apiClient.delete(`sentences/${sentenceId}/file`, options);
    }
    
    updateSentence(sentenceId, data, files = []) {
        return this.apiClient.putFromData(`sentences/${sentenceId}`, files, data);
    }

    validateSentence(data) {
        return this.apiClient.postFormData(`sentences/validate`, [], data);
    }
}
