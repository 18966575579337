import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SentenceService} from 'src/app/service/sentence.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ImportDialogComponent} from '../import-dialog/import-dialog.component';
import {BaseComponent} from 'src/app/base/base.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExportDialogComponent} from '../export-dialog/export-dialog/export-dialog.component';

export interface SentenceElement {
  id: string;
  word_name: string;
  sentence_en: string;
  sentence_jp: string;
  audio: string;
  visibility: boolean;
}

@Component({
  selector: 'app-sentesces',
  templateUrl: './sentesces.component.html',
  styleUrls: ['./sentesces.component.scss'],
})
export class SentescesComponent extends BaseComponent implements OnInit {
  router: Router;
  displayedColumns = [
    'id',
    'word_name',
    'sentence_en',
    'sentence_jp',
    'principle',
    'audio',
    'visibility',
    'action',
  ];
  dataSource = new MatTableDataSource([]);
  sentences = [];
  isLoading = true;

  constructor(
    private sentenceServicve: SentenceService,
    private route: ActivatedRoute,
    _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    super(_snackBar, dialog);

    this.dataSource.sort = this.sort;

    this.router = _router;
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.loadData();
  }

  private saveWordsToDB() {
    localStorage.setItem('sentences_key', JSON.stringify(this.sentences));
  }

  addSentence() {
    this.router.navigate(['/sentences/create']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ImportDialogComponent, {
      data: {dialogType: 'sentences'},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }

  deleteSentence(sentence) {
    let id = sentence.id;
    this.sentences = this.sentences.filter((item) => item.id !== id);
    this.dataSource.data = this.viewData(this.sentences);
    this.dataSource.sort = this.sort;
    this.sentenceServicve.deleteSentence(id).subscribe((data) => {
      if (data && data.data) {
        this.showSnackBar(`Sentence has been deleted`);
      }
    });
  }

  private loadData() {
    this.sentenceServicve.getSentences().subscribe((data) => {
      this.isLoading = false;
      if (data && data.data) {
        this.sentences = data.data;
        this.saveWordsToDB();
        this.dataSource.data = this.viewData(data.data);
        this.dataSource.sort = this.sort;
      }
    });
  }

  private viewData(data) {
    return data.map((element) => {
      const isPrinciple = element.principle && element.principle !== '';
      const firstCapital = element.word_name.charAt(0).toUpperCase() + element.word_name.slice(1);
      const contains = element.sentence_en.includes(element.word_name) || element.sentence_en.includes(firstCapital);
      const principleNeeded = !contains && !isPrinciple;

      return {
        id: element.id,
        word_name: element.word_name,
        sentence_en: element.sentence_en,
        sentence_jp: element.sentence_jp,
        principle: principleNeeded ? '*principle needed!*' : element.principle,
        audio: `${element.audio.length} files`,
        visibility: element.visibility,
      };
    });
  }

  openExportDailog() {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      data: {
        dialogType: 'sentences',
        availableFields: [
          'id',
          'sentence_en',
          'sentence_jp',
          'principle',
          'unit_id',
          'word_id',
          'word_name',
        ],
      },
    });
  }
}
