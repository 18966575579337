import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BonusesService } from 'src/app/service/bonuses.service';
import { BaseComponent } from 'src/app/base/base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bonuses',
  templateUrl: './bonuses.component.html',
  styleUrls: ['./bonuses.component.scss']
})
export class BonusesComponent extends BaseComponent implements OnInit {
  bonusesReactiveForm: FormGroup;
  isLoading = true;
  selectedLevel = 'Level 5';

  levels: string[] = [
    'Level 5',
    'Level 4',
    'Level 3',
    'Level pre-2',
    'Level 2',
    'Level pre-1',
    'Level 1'
  ];

  constructor(
    private fb: FormBuilder, 
    private service: BonusesService,     
    private _snackBar: MatSnackBar,    
    public dialog: MatDialog
  ) {    
    super(_snackBar, dialog);
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.initForms();
  }

  private initForms() {
    this.bonusesReactiveForm = this.fb.group({
      user_id: ['', [
				Validators.required,
      ]],
      levelControl: new FormControl(),
    });
  }

  get f() {
    return this.bonusesReactiveForm.controls;
  }

  onSaveFreeEiken() {
    let model = {
      user_id: this.f.user_id.value,
      eiken_level: this.selectedLevel,
    }
    this.service.giveFreeEiken(model).subscribe((data) => {
      if (data && data.data) {
        this.showSnackBar(`Given free eiken level for ${model.user_id}.`);
      }
    });
  }
}
